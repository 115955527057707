<template>
  <div class="box">
      <div class="eimg">
        <img :src="TName.img" alt="">
      </div>
      <span class="name">{{TName.tname}}</span>
  </div>
</template>

<script>
export default {
  name: 'TCode',
  props: {
    TName: {
      type: Object
    }
  }
}
</script>

<style scoped>
.box{
    position: absolute;
    width: 80px;
    height: 150px;
}
.eimg{
    position: absolute;
    width: 80px;
    height: 80px;
    left: 0px;
    border-radius: 0px;
}
.name{
    position: absolute;
    width: 60px;
    height: 18px;
    left: 10px;
    top: 80px;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #262626;
}
img{
  width: 100%;
}
</style>
