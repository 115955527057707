var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box",style:(_vm.activeStyle)},[_c('a',{staticClass:"tttt",style:({
      color: _vm.titlesIndex === 0 ? '#262626 ' : '#595959 ',
      'font-weight': _vm.titlesIndex === 0 ? 'blod' : '',
    }),attrs:{"href":_vm.titlesItem.infoType === 'audio'
        ? '/mobVideoCenterAudioDel?audioId=' + _vm.titlesItem.infoId
        : _vm.titlesItem.infoType === 'article'
        ? '/mobVideoCenterArticleDel?articleId=' + _vm.titlesItem.infoId
        : '/mobVideoCenterDel?videoId=' + _vm.titlesItem.infoId}},[_vm._v(_vm._s(_vm.titlesItem.title))])])}
var staticRenderFns = []

export { render, staticRenderFns }