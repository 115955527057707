<template>
  <div id="home" class="wrapper">
    <div class="daohang zzjz" >
      <div class="dh"></div>
      <div class="keshi">科室快捷导航</div>
        <!-- <list-scroll class="nav-side-wrapper"> -->
            <ul class="departments" style="z-index : 999">
              <li v-for="(item, index) in dahang" :key="index"  @mouseover="mouseOver(index)" :style="{ background: (chooseIndex === index) ?'#4263EB' : 'rgba(217,227,252,0.01)', color: (chooseIndex === index) ?'#FFFFFF' : '#000000'}"
                @mouseleave="mouseLeave()">
                <a>{{item.dempFirst}}
                <span>></span>
                </a>
              </li>
          </ul>
        <!-- </list-scroll> -->
      <div class="departments_list" :class="{actived: isactived}"  @mouseover="mouseOver2()"
              @mouseleave="mouseLeave2()">
        <div class="dempzong">
          <el-row >
          <span class="dempTitle">{{dahang[nowIndex].dempFirst}}科室</span>
          <div style="margin-top:4px">
          <span class="classifyBox">
            <a @click="disto(dahang[nowIndex].dempFirst, option, nowIndex, index)" v-for="(option, index) in (dahang[nowIndex].dempSecond ? dahang[nowIndex].dempSecond : '')" :key="index">{{option}}<el-divider direction="vertical" ></el-divider></a>
          </span>
          </div>
          </el-row>
          <!-- <el-row >
            <el-col class="dahangtextsize" :span="4" v-for="(option, index) in dahang[nowIndex].dempSecond" :key="index">{{option}}<el-divider direction="vertical" v-if="!((index+1) % 5 ===0)" ></el-divider></el-col>
          </el-row> -->
          <el-row style="margin-top:8px">
            <span class="dempTitle">{{dahang[nowIndex].dempFirst}}常见疾病</span>
            <div style="margin-top:4px">
            <span class="classifyBox">
              <a @click="dis(option.tagId)" v-for="(option, index) in dahang[nowIndex].illnessList" :key="index">{{option.illness}} <el-divider direction="vertical" ></el-divider></a>
            </span>
            </div>
          </el-row>
          <!-- <el-row >
            <el-col class="dahangtextsize" :span="4" v-for="(option, index) in dahang[nowIndex].dempSecond" :key="index">{{option}}<el-divider direction="vertical" v-if="!((index+1) % 5 ===0)" ></el-divider></el-col>
          </el-row> -->
        </div>
      </div>
      <div class="swiper">
        <div class="block">
          <el-carousel height="372px" style="margin-block-start: unset">
            <el-carousel-item v-for="(item, index) in bannerList" :key="index" @click.native="topage(item.h5Url)">
              <h3 class="small">
                <img :src="item.bannerImg" width="100%" height="100%" alt />
              </h3>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="news">
        <span class="sstitle">
          <img src="~assets/jk.svg" alt="">
        </span>
        <div style="position: relative; margin-top: -10px">
          <small-videos-list :videos="videos"></small-videos-list>
        </div>
        <!-- <hel-new-list :news="news" class="new"></hel-new-list> -->
      </div>
    </div>
    <div class="frame">
        <t-code :t-name="timg1" class="peopleapp"></t-code>
        <t-code :t-name="timg2" class="docapp"></t-code>
    </div>
    <div class="todayrecommend zzjz">
       <home-today-title class="todaytitle"></home-today-title>
       <div class="tbox">
        <!-- <videos-list :videos="videos" class="video" :type="'today'"></videos-list> -->
        <el-row class="row1">
          <el-col :span="24" v-for="(project, index) in videos" :key="project.pid" :offset="1">
            <videos-list-itemtest :videos-item="project" :type="'video'" class="now" v-if="index < 2"></videos-list-itemtest>
          </el-col>
      </el-row>
        <t-list :titles="TitleList" class="msg1"></t-list>
        <t-list :titles="TitleList2" class="msg2"></t-list>
        <div class="gg">
          <!-- <img src="~assets/banner.png" alt=""> -->
          <div v-if="rsiderList.length>0">
              <img :src="rsiderList[0].bannerImg" alt="" @click="topage(rsiderList[0].h5Url)">
            </div>
            <div v-else>
              <!-- <img src="~assets/banner.png" alt=""> -->
            </div>
        </div>
       </div>
    </div>
   <div class="docvideo zzjz">
      <recommend-title :Title="'医学视频'" :eTitle="'MEDICAL VIDEO'" :color="'#4263EB'" class="rectitle"></recommend-title>
      <div class="ylist">
       <div v-for="(item,index) in options" class="btn" :class="{sactived: index === currentIndex2}" @click="itemClick2(index,'video')" :key="index">{{item}}</div>
     </div>
     <a class="showmore" @click="toshowmore(1)">查看更多>></a>
     <!-- <videos-list :videos="videoss" class="yyvideo" :type="'video'"></videos-list> -->
     <el-row class="row">
          <el-col :span="5" v-for="project in docaudios" :key="project.infoId" :offset="1">
              <videos-list-itemtest :videos-item="project" class="now"></videos-list-itemtest>
          </el-col>
      </el-row>
   </div>
   <div class="yy zzjz">
     <recommend-title :Title="'语音答疑'" :eTitle="'AUDIO ANSWER'" :color="'#F6A609'" class="rectitle"></recommend-title>
     <div class="ylist">
       <!-- <options-list :options="options" :now="now"></options-list> -->
       <div v-for="(item,index) in options" class="btn" :class="{sactived: index === currentIndex}" @click="itemClick(index,'audio')" :key="index">{{item}}</div>
     </div>
     <a class="showmore" @click="toshowmore(2)">查看更多>></a>
     <!-- <videos-list :videos="videoss" class="yyvideo" :type="'voice'"></videos-list> -->
     <el-row class="row">
          <el-col :span="5" v-for="project in docvideos" :key="project.infoId" :offset="1">
              <videos-list-itemtest :videos-item="project" class="now" :type="'voice'"></videos-list-itemtest>
          </el-col>
      </el-row>
   </div>
   <div class="tdoc zzjz">
     <recommend-title :Title="'三甲名医'" :eTitle="'TOP FAMOUS DOCTORS'" :color="'#2AC769'" class="rectitle"></recommend-title>
     <a class="showmore" @click="toshowmore(3)">查看更多>></a>
     <el-row class="row2">
          <el-col :span="25" v-for="project in doctors" :key="project.doctorId" :offset="1">
              <doctors-list-itemtest :doctors-item="project" class="now"></doctors-list-itemtest>
          </el-col>
      </el-row>
   </div>
   <div class="article zzjz">
      <recommend-title :Title="'精选文章'" :eTitle="'FEATURED ARTICLES'" :color="'#7048E8'" class="rectitle"></recommend-title>
      <a class="showmore" @click="toshowmore(4)">查看更多>></a>
      <div class="midbox">
        <div class="img1">
            <div v-if="lsiderList.length>0">
              <img :src="lsiderList[0].bannerImg" alt="" @click="topage(lsiderList[0].h5Url)">
            </div>
            <div v-else>
              <!-- <img src="~assets/banner.png" alt=""> -->
            </div>
        </div>
        <div class="img2">
            <div v-if="lsiderList.length>1">
              <img :src="lsiderList[1].bannerImg" alt="" @click="topage(lsiderList[1].h5Url)">
            </div>
            <div v-else>
              <!-- <img src="~assets/banner.png" alt=""> -->
            </div>
        </div>
        <t-list :titles="articleList" class="msg1"></t-list>
        <t-list :titles="articleList2" class="msg2"></t-list>
        <div class="bgbox">
            <div v-if="rsiderList.length>1">
              <img :src="rsiderList[1].bannerImg" alt="" @click="topage(rsiderList[1].h5Url)">
            </div>
            <div v-else>
              <!-- <img src="~assets/banner.png" alt=""> -->
            </div>
        </div>
        </div>
   </div>
   <div class="docanswer zzjz">
     <a class="showmore" @click="toshowmore(5)">查看更多>></a>
      <recommend-title :Title="'名医问答'" :eTitle="'Q & A WITH DOCTORS'" :color="'#2AC769'" class="rectitle"></recommend-title>
      <question-answer-list :question="question" class="qw"></question-answer-list>
   </div>
   <bottom-bar class="bottom"></bottom-bar>
  </div>

</template>

<script>
import SmallVideosList from 'components/content/videos/SmallVideosList'
import HomeTodayTitle from './childComps/HomeTodayTitle'
import RecommendTitle from 'components/content/recommendtitle/RecommendTitle'
import DoctorsListItemtest from 'components/content/doctors/DoctorsListItemtest'
import QuestionAnswerList from 'components/content/questionanswer/QuestionAnswerList'
import BottomBar from 'components/content/bottombar/BottomBar'
import TList from 'components/content/tlist/TList'
import TCode from 'components/common/tcode/TCode'
import VideosListItemtest from 'components/content/videos/VideosListItemtest'
// 获取轮播图
import { getDepartment, getMediaInfoList, getDoctorInfoList, getdetailList, getBanner, getTitle } from 'network/getData'
// import { Encrypt } from '@/util/EncryptionToDecrypt'
export default {
  name: 'Home',
  components: {
    HomeTodayTitle,
    RecommendTitle,
    DoctorsListItemtest,
    QuestionAnswerList,
    BottomBar,
    TList,
    SmallVideosList,
    TCode,
    VideosListItemtest
  },
  data () {
    return {
      isactived: false,
      nowIndex: 0,
      chooseIndex: -1,
      ischoose: true,
      currentIndex: 0,
      currentIndex2: 0,
      iscroll: '',
      docaudios: [],
      docvideos: [],
      bannerList: [],
      timg1: {
        tname: '用户版App下载',
        img: require('../../assets/kh.png')
      },
      timg2: {
        tname: '医生版App下载',
        img: require('../../assets/ys.png')
      },
      dahang: ['内科', '外科', '妇产科', '儿科', '男科'],
      dismsg: {
        dempFirst: '',
        dempSecond: ''
      },
      banners: [
        {
          image: ''
        },
        {
          image: ''
        },
        {
          image: ''
        }
      ],
      title: [
      ],
      options: [
        '最新',
        '最热',
        '男科',
        '妇科',
        '皮肤科'
      ],
      news: [
      ],
      question: [],
      videos: [],
      videoss: [
      ],
      doctors: [],
      change: ['latest', 'popular', 'nank', 'fk', 'pfk'],
      isMoreLoad: true,
      isMoreLoad2: true,
      TitleList: [],
      TitleList2: [],
      articleList: [],
      articleList2: [],
      rsiderList: [],
      lsiderList: []
    }
  },
  // metaInfo () {
  //   return {
  //     meta: [
  //       { name: 'keywords', content: '科普视频,专家文章,专家音频,名医问答,查疾病,瑞尔安心、瑞尔良医、瑞尔良医app、瑞尔安心官网、瑞尔视景、瑞尔视景科技有限公司、单道动态心电记录仪、7天可穿戴心电监测设备、智能心电监测设备' },
  //       { name: 'description', content: '瑞尔安心在线平台,专家,疾病,科室等详细信息,由专家生产的科普视频,文章,音频等内容,瑞尔安心平台向全民提供“科普视频+语音问答+图文咨询+文章+大数据分析”多位一体的精准健康科普知识，满足大众多样化、多场景应用的健康科普需求。致力于通过互联网技术推动医生共享、知识共享、服务共享、经济共享，放大医生价值和健康科普内容价值，用通俗易懂的方式以及直白语言，制作出大众听得懂、记得住、学得会、用得上的健康科普知识，切实提升公众健康素养，让更多人能够健康地享受美好生活，助力全民健康。研发出多款医疗器械，实现了心脑血管病患者、高危人群得日常疾病筛查和健康管理，并配套了全程专业的健康管理服务，在国内医疗产品行业中具有很高的地位和影响。公司主要从事公司下设医学研发中心、软件开发中心、生产制造中心、营销中心、医学服务中心。' }
  //     ]
  //   }
  // },
  methods: {
    itemClick (index, e) {
      this.currentIndex = index
      // console.log(index, this.change[index])
      this.getMediaInfoListdata(this.change[index], 8, 1, e)
      // console.log('xxx', this.getMediaInfoListdata(this.change[index], 8, 1, e))
    },
    itemClick2 (index, e) {
      this.currentIndex2 = index
      // console.log(index, this.change[index])
      this.getMediaInfoListdata(this.change[index], 8, 1, e)
      // console.log('xxx', this.getMediaInfoListdata(this.change[index], 8, 1, e))
    },
    // 通过请求获取数据
    async getDepartmentdata () {
      try {
        // 获取轮播图数据列表
        const result = await getDepartment()
        // console.log(result + '11111111111111111111')
        const tempdemp = result.data.data.demp
        tempdemp.splice(tempdemp.length - 2, 2)
        this.dahang = tempdemp
        // console.log(this.dahang + '11111111111111111111')
      } catch (error) {
        // console.log(error)
      }
    },
    async getMediaInfoListdata (filterCondition, row, page, type) {
      try {
        // 获取轮播图数据列表
        const result = await getMediaInfoList(filterCondition, row, page, type)
        if (type === 'video' && row === 8) {
          this.docaudios = result.data.data
        }
        if (type === 'video' && row === 5) {
          this.videos = result.data.data
        }
        if (type === 'audio') {
          this.docvideos = result.data.data
        }
        if (type === 'article') {
          const list = result.data.data
          const half = Math.ceil(list.length / 2)
          this.articleList = list.splice(0, half)
          this.articleList2 = list.splice(-half)
          // console.log('this.articleList', this.articleList, this.articleList2)
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async getDoctorInfoListdata (filterCondition, row, page, type) {
      try {
        const result = await getDoctorInfoList(filterCondition, row, page, type)
        this.doctors = result.data.data
      } catch (error) {
        // console.log(error)
      }
    },
    async getdetailListdata (rows, page, filterCondition, type, infoTag) {
      try {
        const result = await getdetailList(rows, page, filterCondition, type, infoTag)
        this.question = result.data.data
        // console.log(this.question, 'question')
      } catch (error) {
        // console.log(error)
      }
    },
    async getBannerList (type) {
      try {
        const result = await getBanner(type)
        if (type === 'index') {
          this.bannerList = result.data.data
        } else if (type === 'rsider') {
          this.rsiderList = result.data.data
        } else if (type === 'lsider') {
          this.lsiderList = result.data.data
        }
        // console.log('this.bannerList', this.bannerList)
      } catch (error) {
        // console.log(error)
      }
    },
    async getTitleList () {
      try {
        const result = await getTitle()
        const list = result.data.data
        const half = Math.ceil(list.length / 2)
        this.TitleList = list.splice(0, half)
        this.TitleList2 = list.splice(-half)
        // console.log('this.TitleList', this.TitleList, this.TitleList2)
      } catch (error) {
        // console.log(error)
      }
    },
    mouseOver (index) {
      this.nowIndex = index
      this.chooseIndex = index
      this.isactived = true
      // console.log(this.nowIndex)
    },
    mouseOver2 () {
      this.isactived = true
      this.ischoose = true
    },
    topage (e) {
      const asd = e.split('|')
      if (asd[1] === 'video') {
        // sessionStorage.setItem('nav', '2')
        sessionStorage.setItem('id', asd[0])
        this.$router.push({ path: '/mobVideoCenterDel', query: { videoId: asd[0] } })
      } else if (asd[1] === 'audio') {
        // sessionStorage.setItem('nav', '3')
        sessionStorage.setItem('id', asd[0])
        this.$router.push({ path: '/mobVideoCenterAudioDel', query: { audioId: asd[0] } })
      } else if (asd[1] === 'article') {
        // sessionStorage.setItem('nav', '4')
        sessionStorage.setItem('id', asd[0])
        this.$router.push({ path: '/mobVideoCenterArticleDel', query: { articleId: asd[0] } })
      } else if (asd[1] === 'qa') {
        // sessionStorage.setItem('nav', '5')
        this.$router.push({ path: '/mobQuestionCenterDel', query: { qaId: asd[0] } })
      } else if (e === 'empty') {
        return ''
      } else {
        window.open(e)
      }
    },
    // 移出
    mouseLeave () {
      this.isactived = false
      if (!this.ischoose) {
        this.chooseIndex = -1
      }
    },
    mouseLeave2 () {
      this.ischoose = false
      this.isactived = false
      this.chooseIndex = -1
    },
    disto (e, g, i, j) {
      // // console.log(e, g, '1111111111111111111111')
      // this.$router.push('/disease')
      this.$router.push({ path: 'disease', query: { dempFirst: e, dempSecond: g, index: i, eindex: j } })
    },
    dis (e) {
      this.$router.push({ path: 'disease/diseasedetail', query: { i: e } })
    },
    toshowmore (index) {
      if (index === 1) {
        this.$router.push('/mobVideoCenter/')
      }
      if (index === 2) {
        this.$router.push('/mobAudioCenter/')
      }
      if (index === 3) {
        this.$router.push('/mobVideoDoctorMore/')
      }
      if (index === 4) {
        this.$router.push('/mobArticleCenter/')
      }
      if (index === 5) {
        this.$router.push('/mobQuestionCenter/')
      }
    }
  },
  mounted () {
    // document.addEventListener('touchmove', function (ev) { ev.preventDefault() }, { passive: false })
    // this.iscroll = new IScroll('#wrapper', {
    //   mouseWheel: true, // 允许鼠标滚轮
    //   scrollbars: false
    // })
    // 过滤条件（latest/popular/nk/fk/pfk） 数据类型 （audio/video/qa/article）
    this.getMediaInfoListdata('popular', 5, 1, 'video')
    this.getTitleList()
    this.getBannerList('index')
    this.getBannerList('rsider')
    this.getBannerList('lsider')
    const _this = this
    window.addEventListener('scroll', function () {
      const scr = document.documentElement.scrollTop || document.body.scrollTop // 向上滚动的那一部分高度
      // const clientHeight = document.documentElement.clientHeight // 屏幕高度也就是当前设备静态下你所看到的视觉高度
      const scrHeight = document.documentElement.scrollHeight || document.body.scrollHeight // 整个网页的实际高度，兼容Pc端
      // console.log(scr, clientHeight, scrHeight)
      if (scr / scrHeight > 0.08 && _this.isMoreLoad === true) {
        _this.getMediaInfoListdata('latest', 8, 1, 'video')
        _this.getMediaInfoListdata('latest', 8, 1, 'audio')
        _this.isMoreLoad = false
      } else if (scr / scrHeight > 0.36 && _this.isMoreLoad2 === true) {
        _this.getMediaInfoListdata('latest', 20, 1, 'article')
        _this.getDoctorInfoListdata('', 5, 1, 'doctor')
        _this.getdetailListdata(4, 1, 'latest', 'qa', '')
        _this.isMoreLoad2 = false
      }
    })
    // console.log(this.iscroll)
  },
  created () {
    this.getDepartmentdata()
  }
}
</script>

<style scoped>
#home{
  /* position: absolute; */
  margin-left:120px;
  margin-right:120px;
}
a{
  cursor: pointer;
}
.daohang{
  position: absolute;
  width: 1200px;
  height: 408px;
  /* left: 120px; */
  top: 100px;
}
.sstitle{
  position: absolute;
  width: 96px;
  height: 28px;
  left: 12px;
  top: 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: #262626;
}
/* @font-face{
    font-family: 'pang';
    src : url('~assets/font/pang.ttf');
} */
.news .sstitle{
  font-family: 'PangMenZhengDao-3';
}
.news{
position: absolute;
width: 291px;
height: 382px;
left: 909px;
top: 26px;
border: 1px solid #EAEDF0;
box-sizing: border-box;
border-radius: 0px;
}
.new{
  position: absolute;
  top: 60px;
  left: 12px;
}
.todayrecommend{
  position: absolute;
  width: 1200px;
  height: 522px;
  /* left: 120px; */
  /* top: 563px; */
  top: 600px;
}
.todaytitle{
  position: absolute;
  width: 258px;
  height: 70px;
  left: 471px;
  top: 0px;
}
.video{
  position: absolute;
  width: 222px;
  height: 216px;
  left: 10px;
  top: -50px;
}
.msg1{
  position: absolute;
  width: 310px;
  height: 397px;
  left: 252px;
  top: 0px;
}
.msg2{
  position: absolute;
  width: 310px;
  height: 397px;
  left: 582px;
  top: 0px;
}
.gg{
  position: absolute;
  width: 288px;
  height: 396px;
  left: 910px;
  top: 0px;
  /* background: #C4C4C4; */
}
.gg img{
  width: 100%;
  height: 100%;
}
.rectitle{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  position: absolute;
  width: 300px;
  height: 72px;
  left: 450px;
  top: 0px;
}
.qw{
  position: absolute;
  top: 122px;
}
.docanswer{
  position: absolute;
  width: 1200px;
  height: 635px;
  /* left: 120px; */
  top: 3951px;
}
.bottom{
  position: absolute;
  /* width: 1440px; */
  /* height: 594px; */
  /* left: 0px; */
  margin-left: -120px;
  top: 4571px;
  background: #FFFFFF;
}
.article{
  position: absolute;
  width: 1200px;
  height: 529px;
  /* left: 120px; */
  top: 3327px;
}
.bgbox{
  position: absolute;
  width: 288px;
  height: 396px;
  left: 912px;
  top: 1px;
  /* background: #C4C4C4; */
}
img{
  width: 100%;
  height: 100%;
}
.img1{
  position: absolute;
  width: 230px;
  height: 150px;
  left: 0px;
  top: 0px;
  /* background: #C4C4C4; */
}
.img2{
  position: absolute;
  width: 230px;
  height: 150px;
  left: 0px;
  top: 170px;
  /* background: #C4C4C4; */
}
.img1 img,
.img2 img{
  width: 100%;
  height: 100%;
}
.midbox{
  position: absolute;
  width: 1200px;
  height: 397px;
  left: 0px;
  top: 122px;
}
.imsg1{
  position: absolute;
  width: 112px;
  height: 24px;
  left: 16px;
  top: 16px;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 24px;
}
.imsg2{
  position: absolute;
  width: 84px;
  height: 20px;
  left: 16px;
  top: 38px;
  color: #E8E8E8;
  font-size: 12px;
  line-height: 20px;
}
.tbox{
  position: absolute;
  width: 1200px;
  height: 397px;
  left: 0px;
  top: 122px;
}
.tdoc{
  position: absolute;
  width: 1200px;
  height: 590px;
  /* left: 120px; */
  top: 2652px;
}
.showmore{
  position: absolute;
  width: 80px;
  height: 22px;
  right: -30px;
  top: 88px;
  font-size: 14px;
  line-height: 22px;
  color: #8C8C8C;
}
.showmore:hover{
  color: #000000;
}
.doclist{
  position: absolute;
  top: 136px;
}
.yy{
  position: absolute;
  width: 1200px;
  height: 631px;
  /* left: 120px; */
  top: 1958px;
}
.ylist{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: absolute;
  /* left: 20px; */
  top: 123px;
  cursor: pointer;
}
.yyvideo{
  position: absolute;
  top: 1px;
  left: 192px;
}
.docvideo{
  position: absolute;
  width: 1200px;
  height: 631px;
  /* left: 120px; */
  top: 1260px;
}
.dh{
  position: absolute;
  width: 230px;
  height: 408px;
  left: 0px;
  top: 0px;
  background: #D9E3FE;
  opacity: 0.2;
  border-radius: 0px;
}
.keshi{
  width: 230px;
  height: 22px;
  left: 67px;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #262626;
  margin-top: 12px;
  /* background: rgba(217,227,254,0.2); */
}
.swiper{
  position: absolute;
  width: 655px;
  /* height: 382px; */
  /* background: #D9D9D9; */
  left: 242px;
  top: 26px;
}
.frame{
  position: fixed;
  height: 230px;
  left: 93.36%;
  right: 0.69%;
  top: 275px;
  z-index: 999;
  }
.peopleapp{
  position: absolute;
  height: 110px;
  left: 0%;
  right: 0%;
  top: 0px;
  }
.docapp{
  position: absolute;
  height: 110px;
  left: 0%;
  right: 0%;
  top: 150px;
}
/* li a {
  position: relative;
  display: block;
  padding: 0 26px 0 82px;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  color: #595959;
}
li{
  list-style-type: none;
}
li a span {
    position: absolute;
    right: 28px;
    top: 0;
    width: 7px;
    height: 12px;
    background-position: right -343px;
} */
/* .departments {
    position: absolute;
    top: 0;
    left: -40px;
    width: 230px;
    height: 360px;
    padding-top: 40px;
    overflow: hidden;
} */
.departments_list{
  display: none;
  position: absolute;
  width: 420px;
  height: 408px;
  left: 230px;
  top: 1px;
  background: rgba(255, 255, 255, 0.98);
  z-index: 999;
}
.classifyName {
    height: 18px;
    line-height: 1;
    font-size: 16px;
    color: #222;
    padding: 20px 0 12px 12px;
    clear: both;
}
.classifyBox a {
    float: left;
    line-height: 20px;
    /* border-right: 1px solid #ddd; */
    overflow: hidden;
    font-size: 12px;
    margin-bottom: 8px;
    color: #595959;
  /* color: #000000; */
}
a{
  text-decoration: none;
}
.actived{
  display: block;
}
.departments li:hover{
  text-decoration: none;
  background: #4263EB;
  /* border-left: 3px solid #FF4000; */
  color: #FFFFFF;
}
.now{
  position: relative;
}
.el-col-5{
  width: 222px;
}
.span{
  width: 222px;
}
.el-col-offset-1{
  margin-left: 4%;
  margin-bottom: 1%;
}
.el-col :hover{
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
}
.now :hover{
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(0, 0, 0, 0);
}
.row{
  position: relative;
  top: 120px;
  left: 160px;
}
.row1{
  /* top: -40px; */
  left: -50px;
}
.row2{
  top: 130px
}
.el-col-25{
  width: 16%;
}
.btn{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* padding: 4px 28px; */
  position: static;
  text-align: center;
  background: #F5F5F5;
  padding: 10px 20px;
  margin-bottom: 20px;
  justify-content: center;
  width: 80px;
  border-radius: 4px;
  /* width: 84px;
  height: 30px;
  left: 0px;
  top: 50px;
  background: #F5F5F5;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-bottom: 20px; */
}
.sactived{
background: #4263EB;
color: #FFFFFF;
}
.search-wrap{
  /* position:relative; */
  /* margin:50px auto; */
  height:360px;
  /* width:200px; */
  overflow:hidden;
}
.departments{
  /* height: 100%; */
  height: 560px;
  overflow: hidden;
  /* margin-top: 40px; */
}
.departments li{
  display: block;
  height: 35px;
  padding-left: 30px;
  margin-left: -40px;
  width: 200px;
  list-style-type: none;
  position: relative;
}
.departments li a{
  line-height: 35px;
  font-size: 14px;
  padding-left: 50px;
}
.dempTitle{
  font-size: 16px;
  color: #262626;
  margin-bottom: 8px;
}
.dempzong{
  margin: 0px 12px;
}
.classifyBox :hover{
    color:#6F8CF3;
}
</style>
