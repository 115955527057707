<!--
 * @Author: your name
 * @Date: 2021-12-16 12:25:25
 * @LastEditTime: 2021-12-17 10:09:12
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \website\src\components\content\tlist\TList.vue
-->
<template>
  <div class="titles">
    <t-list-item v-for="(item,index) in titles" :titles-item="item" :titles-index="index" :key="index" :style="{color : (index === 0) ? '#262626' : '#595959', 'font-weight' : (index === 0) ? 'blod' : ''}"></t-list-item>
  </div>
</template>

<script>
import TListItem from './TListItem'
export default {
  name: 'TList',
  components: {
    TListItem
  },
  props: {
    titles: {
      type: Array,
      default () {
        return []
      }
    }
  }
}
</script>

<style scoped>
.titles{
    position: absolute;
    width: 310px;
    height: 397px;
}
</style>
