<template>
  <div class="title">
    <div class="left"><slot name="left"></slot></div>
    <div class="right"><slot name="right"></slot></div>
  </div>
</template>

<script>
export default {
  name: 'TodayTitle'
}
</script>

<style scoped>
.title{
  width: 258px;
  height: 70px;
  display: flex;
  /* line-height: 70px; */
  align-items: center;
}
.left{
  width: 62px;
  height: 56px;
  /* background-color: blue; */
}
.right{
  width: 198px;
  height: 70px;
  /* background-color: red; */
}
</style>
