<template>
  <div class="box" :style="activeStyle">
    <!-- <a @click="toarticle(titlesItem.infoId, titlesItem.infoType)">{{titlesItem.title}}</a> -->
    <a
      class="tttt"
      :href="
        titlesItem.infoType === 'audio'
          ? '/mobVideoCenterAudioDel?audioId=' + titlesItem.infoId
          : titlesItem.infoType === 'article'
          ? '/mobVideoCenterArticleDel?articleId=' + titlesItem.infoId
          : '/mobVideoCenterDel?videoId=' + titlesItem.infoId
      "
      :style="{
        color: titlesIndex === 0 ? '#262626 ' : '#595959 ',
        'font-weight': titlesIndex === 0 ? 'blod' : '',
      }"
      >{{ titlesItem.title }}</a
    >
  </div>
</template>

<script>
// import { Encrypt } from '@/util/EncryptionToDecrypt'
export default {
  name: 'TListItem',
  props: {
    titlesItem: {
      type: Object
    },
    titlesIndex: {
      type: Number
    }
  },
  computed: {
    activeStyle () {
      return { top: (0 + 40 * this.titlesIndex) + 'px' }
    }
  },
  methods: {
    toarticle (e, j) {
      if (j === 'audio') {
        // this.$router.push('/voicedetail/' + 1)
        // // sessionStorage.setItem('nav', '3')
        // sessionStorage.setItem('id', e)
        // this.$router.push({ path: '/mobVideoCenterAudioDel' })
        this.$router.push({ path: '/mobVideoCenterAudioDel', query: { audioId: e } })
      }
      if (j === 'article') {
        // // sessionStorage.setItem('nav', '4')
        // this.$router.push('/articledetail/' + 1)
        // sessionStorage.setItem('id', e)
        // this.$router.push({ path: '/mobVideoCenterArticleDel' })
        this.$router.push({ path: '/mobVideoCenterArticleDel', query: { articleId: e } })
      }
      if (j === 'video') {
        // // sessionStorage.setItem('nav', '2')
        // this.$router.push('/videodetail/' + 1)
        // sessionStorage.setItem('id', e)
        // this.$router.push({ path: '/mobVideoCenterDel' })
        this.$router.push({ path: '/mobVideoCenterDel', query: { videoId: e } })
      }
    }
  }
}
</script>

<style scoped>
.box{
    position: absolute;
    width: 300px;
    height: 24px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    margin-left: 20px;
    text-overflow:ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
a{
  cursor: pointer;
  text-decoration:none;
  /* font-family: 'OPPOSans M'; */
}
.box:hover{
  color: #BFBFBF !important;
}
.box:first-child {
   font-size: 20px;
   line-height: 28px;
}
.number {
    width: 128px;
    height: 24px;
    left: 32px;
    position: absolute;
}
</style>
