<template>
<div>
  <today-title>
    <div slot="left">
      <div class="month">
        {{Month}}
      </div>
      <div class="day">
        {{day}}
      </div>
    </div>
    <div slot="right">
      <!-- <div class="bgbox"></div> -->
      <div class="box">
      <div class="today">
        今日推荐
      </div>
      <div class="etoday">
        TODAY RECOMMEND
      </div>
      </div>
    </div>
  </today-title>
</div>
</template>

<script>
import TodayTitle from 'components/common/todaytitle/TodayTitle.vue'
export default {
  name: 'HomeTodayTitle',
  components: {
    TodayTitle
  },
  data () {
    return {
      Month: '',
      day: ''
    }
  },
  mounted () {
    // 获取当前时间
    this.Month = (new Date().getMonth() + 1) < 10 ? (new Date().getMonth() + 1) + '月' : (new Date().getMonth() + 1) + '月'
    // console.log(this.Month)
    this.day = new Date().getDate() < 10 ? new Date().getDate() : new Date().getDate()
    // console.log(this.day)
  }
}
</script>

<style scoped>
.month{
  background-color: #9674F1;
  color: #FFFFFF;
  text-align: center;
  line-height: 20px;
  font-size: 13px;
  width: 62px;
  height: 20px;
}
.day{
  background-color: #F5F5F5;
  color: #4B4B4B;
  text-align: center;
  line-height: 36px;
  font-size: 23px;
}
.today{
  width: 144px;
  height: 54px;
  font-size: 36px;
  line-height: 54px;
  margin-left: 35px;
  margin-top: -4px;
  color: #262626;
  text-align: center;
  overflow: hidden;
  /* font-family: 'OPPOSans B'; */
  font-weight: bold;
}
.etoday{
  width: 144px;
  height: 14px;
  line-height: 14px;
  text-align: center;
  font-size: 13px;
  margin-left: 35px;
  color: #D3D3D3;
}
.bgbox{
  float: left;
  margin-left: 31px;
  width: 140px;
  height: 45px;
  background-color: #F5F5F5;
}
.box{
  position: absolute;
}
</style>
