<template>
  <div class="videos">
    <question-answer v-for="(item,index) in question" :question-item="item" :question-index="index" :key="index" class="item"></question-answer>
  </div>
</template>

<script>
import QuestionAnswer from './QuestionAnswer'
export default {
  name: 'VideosList',
  components: {
    QuestionAnswer
  },
  props: {
    question: {
      type: Array,
      default () {
        return []
      }
    }
  }
}
</script>

<style scoped>
.videos{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 2px;
}
.videos :hover{
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
}
.videos :active{
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
}
.item :hover{
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.0), 0px 0px 0px rgba(0, 0, 0, 0);
}
</style>
